*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body{
  background: #131619;
}
.muted{
  color: transparent;
  -webkit-text-stroke-color: gray;
  -webkit-text-stroke-width: 1px;
  opacity: 0.5;
}
.Sizef{
  font-size: 10rem;
}
.high{
  height: 50vh;
}
.wid{
  width: 100%;
}
.fle{
  flex: 1.5;
}
.line{
  line-height: 3rem;
}
.letter{
  word-spacing: 0.3rem;
}
.lef{
  left: -6.7rem;
}
.muteed{
  color: transparent;
  -webkit-text-stroke-color: white;
  -webkit-text-stroke-width: 1px;
}
.blur{
  background: rgba(253, 120, 43, 0.68);
  position: absolute;
  border-radius: 50%;
  filter: blur(212px);
  z-index: -1;
}

.blurh{
  height: 50rem;
  width: 25rem;
  right: 0;
}
.blur1{
  width: 30rem;
  height: 20rem;
  top:15rem;
  left: 0;
}
.blur2{
  width: 30rem;
  height: 20rem;
  top:15rem;
  right: 0;
}